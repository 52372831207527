<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row justify="center" align="center" class="ma-2">
          <div>Remover {{ item.name }}?</div>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" class="ma-2" wrap>
          <div>
            Atenção!!! O usuário(a) administrador não poderá mais ter acesso ao
            sistema!
          </div>
          <div>Deseja mesmo excluir?</div>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-around" align="center" class="ma-2">
          <v-btn
            outlined
            color="green"
            :disabled="disabled"
            @click="delAsyncGeneralAdmin()"
            >Remover <v-icon>delete</v-icon></v-btn
          >
          <v-btn
            outlined
            color="red"
            :disabled="disabled"
            @click="closeDialog()"
            >Cancelar <v-icon>clear</v-icon></v-btn
          >
          <v-progress-linear
            indeterminate
            class="mt-5"
            color="red"
            v-show="disabled == true"
          ></v-progress-linear>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeletetGeneralAdmin",
  props: ["item"],
  data() {
    return {
      admin: this.item,
      disabled: false,
    };
  },

  methods: {
    ...mapActions("generalAdmin", ["ActionDeleteGeneralAdmin"]),

    async delAsyncGeneralAdmin() {
      try {
        this.disabled = true;
        await this.ActionDeleteGeneralAdmin(this.admin);
        this.closeDialog();
      } catch (erro) {
        console.log(erro);
      } finally {
        this.disabled = false;
      }
    },

    closeDialog() {
      this.admin = {};
      this.$emit("close-dialog");
    },
  },

   watch: {
    item(newValue){
      this.admin = newValue;
    }
  }
};
</script>
