<template>
  <v-row>
    <v-col class="admin" cols="11">
      <h3 class="ml-3">Administradores</h3>
    </v-col>
    <v-col class="admin" cols="1">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            outlined
            small
            color="green"
            @click="postDialog = !postDialog"
            ><v-icon small>add</v-icon></v-btn
          >
        </template>
        <span>Inserir novo administrador</span>
      </v-tooltip>
    </v-col>

    <v-col cols="12">
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="admins"
        :items-per-page="admins.length"
        item-key="test_date"
        hide-default-footer
        no-data-text="Não há dados disponíveis para a consulta"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.created }}</td>
            <td class="text-xs-left">{{ props.item.createdBy }}</td>
            <td class="text-xs-left">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="props.item.disabled"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    x-small
                    color="red"
                    @click="openDialogDel(props.item)"
                    ><v-icon small>delete</v-icon></v-btn
                  >
                </template>
                <span>Excluir administrador(a) {{ props.item.name }}?</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog
      v-model="postDialog"
      max-width="550"
      @keydown.esc="postDialog = false"
    >
      <PostGeneralAdmin
        v-on:close-dialog="closePostDialog()"
      ></PostGeneralAdmin>
    </v-dialog>

    <v-dialog
      v-model="delDialog"
      max-width="600"
      @keydown.esc="delDialog = false"
    >
      <DeleteGeneralAdmin
        v-on:close-dialog="closeDelDialog()"
        :item="admin"
      ></DeleteGeneralAdmin>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDate } from "@/utils/formatDate";
import { authUtils } from "@/utils/authUtils";
import PostGeneralAdmin from "./PostGeneralAdmin";
import DeleteGeneralAdmin from "./DeleteGeneralAdmin";

export default {
  name: "GeneralAdmin",
  components: { PostGeneralAdmin, DeleteGeneralAdmin },
  data() {
    return {
      show: false,
      admins: [],
      admin: {},
      postDialog: false,
      delDialog: false,
      headers: [
        {
          sortable: true,
          text: "Matrícula",
          value: "academicCode",
          align: "left",
        },
        {
          sortable: true,
          text: "Nome",
          value: "name",
          align: "left",
        },
        {
          sortable: true,
          text: "Criado em",
          value: "created",
          align: "left",
        },
        {
          sortable: true,
          text: "Criado por",
          value: "createdBy",
          align: "left",
        },
        {
          sortable: false,
          text: "Excluir",
          value: "delete",
          align: "left",
        },
      ],
    };
  },
  created() {
    this.getAsyncGeneralAdmin();
  },
  watch: {
    generalAdmin(newValue) {
      if (newValue != null) {
        this.admins = [];
        newValue.map((admin) => {
          this.admins.push({
            disabled: authUtils.getUser().academicCode === admin.academicCode,
            id: admin.id,
            username: admin.username,
            academicCode: admin.academicCode,
            name: admin.name,
            created: formatDate.dmy(admin.createdAt),
            createdBy: admin.createdBy,
          });
        });
      }
    },
  },

  computed: {
    ...mapState("generalAdmin", ["generalAdmin"]),
  },

  methods: {
    ...mapActions("generalAdmin", ["ActionGetGeneralAdmin"]),

    async getAsyncGeneralAdmin() {
      try {
        await this.ActionGetGeneralAdmin();
      } catch (erro) {
        console.log(erro);
      }
    },

    closePostDialog() {
      this.postDialog = false;
    },

    openDialogDel(item) {
      this.delDialog = true;
      this.admin = item;
    },

    closeDelDialog() {
      this.delDialog = false;
    },
  },
};
</script>

<style scoped>
.admin {
  margin: 0%;
  padding: 0%;
}
</style>
