var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { staticClass: "admin", attrs: { cols: "11" } }, [
        _c("h3", { staticClass: "ml-3" }, [_vm._v("Administradores")])
      ]),
      _c(
        "v-col",
        { staticClass: "admin", attrs: { cols: "1" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { left: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                outlined: "",
                                small: "",
                                color: "green"
                              },
                              on: {
                                click: function($event) {
                                  _vm.postDialog = !_vm.postDialog
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("add")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Inserir novo administrador")])]
          )
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-data-table", {
            staticClass: "mt-5",
            attrs: {
              headers: _vm.headers,
              items: _vm.admins,
              "items-per-page": _vm.admins.length,
              "item-key": "test_date",
              "hide-default-footer": "",
              "no-data-text": "Não há dados disponíveis para a consulta"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c("tr", [
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.academicCode))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.name))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.created))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.createdBy))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  disabled: props.item.disabled,
                                                  outlined: "",
                                                  "x-small": "",
                                                  color: "red"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openDialogDel(
                                                      props.item
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("delete")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Excluir administrador(a) " +
                                    _vm._s(props.item.name) +
                                    "?"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.postDialog = false
            }
          },
          model: {
            value: _vm.postDialog,
            callback: function($$v) {
              _vm.postDialog = $$v
            },
            expression: "postDialog"
          }
        },
        [
          _c("PostGeneralAdmin", {
            on: {
              "close-dialog": function($event) {
                return _vm.closePostDialog()
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.delDialog = false
            }
          },
          model: {
            value: _vm.delDialog,
            callback: function($$v) {
              _vm.delDialog = $$v
            },
            expression: "delDialog"
          }
        },
        [
          _c("DeleteGeneralAdmin", {
            attrs: { item: _vm.admin },
            on: {
              "close-dialog": function($event) {
                return _vm.closeDelDialog()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }