var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-2",
                  attrs: { justify: "center", align: "center" }
                },
                [_c("div", [_vm._v("Remover " + _vm._s(_vm.item.name) + "?")])]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "ma-2", attrs: { justify: "center", wrap: "" } },
                [
                  _c("div", [
                    _vm._v(
                      " Atenção!!! O usuário(a) administrador não poderá mais ter acesso ao sistema! "
                    )
                  ]),
                  _c("div", [_vm._v("Deseja mesmo excluir?")])
                ]
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-2",
                  attrs: { justify: "space-around", align: "center" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        color: "green",
                        disabled: _vm.disabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.delAsyncGeneralAdmin()
                        }
                      }
                    },
                    [_vm._v("Remover "), _c("v-icon", [_vm._v("delete")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        color: "red",
                        disabled: _vm.disabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog()
                        }
                      }
                    },
                    [_vm._v("Cancelar "), _c("v-icon", [_vm._v("clear")])],
                    1
                  ),
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.disabled == true,
                        expression: "disabled == true"
                      }
                    ],
                    staticClass: "mt-5",
                    attrs: { indeterminate: "", color: "red" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }