<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row justify="center" align="center" class="ma-2">
          <div>Adicionar Administrador</div>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row justify="space-around" class="ma-2" wrap>
          <v-text-field
            dense
            outlined
            clearable
            counter="15"
            label="username"
            v-model="username"
            :loading="disabled"
            :disabled="disabled"
            :rules="[rules.major, rules.minor]"
            @keypress.enter="postAsyncGeneralAdmin()"
            placeholder="digite a primeira parte do email do funcionário/docente"
          ></v-text-field>
          <v-btn outlined color="green" class="ml-2" :disabled="disabled" @click="postAsyncGeneralAdmin()">Inserir</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PostGeneralAdmin",
  data() {
    return {
      show: false,
      username: '',
      disabled: false,
      rules: {
        major: (v) => (v || "").length < 15 || "O username deve ter menos que 15 caracteres!",
        minor: (v) => (v || "").length > 0 || "O username não pode ser vazio!"
      },
    };
  },

  methods: {
    ...mapActions("generalAdmin", ["ActionPostGeneralAdmin"]),

    async postAsyncGeneralAdmin() {
      try {
        this.disabled = true;
        await this.ActionPostGeneralAdmin({ username: this.username });
        this.username = ""
        this.closeDialog();
      } catch (erro) {
        console.log(erro);
      }finally {
        this.disabled = false;
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>
