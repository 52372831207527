var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-2",
                  attrs: { justify: "center", align: "center" }
                },
                [_c("div", [_vm._v("Adicionar Administrador")])]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-2",
                  attrs: { justify: "space-around", wrap: "" }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      outlined: "",
                      clearable: "",
                      counter: "15",
                      label: "username",
                      loading: _vm.disabled,
                      disabled: _vm.disabled,
                      rules: [_vm.rules.major, _vm.rules.minor],
                      placeholder:
                        "digite a primeira parte do email do funcionário/docente"
                    },
                    on: {
                      keypress: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.postAsyncGeneralAdmin()
                      }
                    },
                    model: {
                      value: _vm.username,
                      callback: function($$v) {
                        _vm.username = $$v
                      },
                      expression: "username"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        outlined: "",
                        color: "green",
                        disabled: _vm.disabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.postAsyncGeneralAdmin()
                        }
                      }
                    },
                    [_vm._v("Inserir")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }